import axios from "axios"
import React, { useEffect, useState } from "react"
import Element from "../UI/Element"
import { Heading } from "../UI/Typography"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import styled from "styled-components"
import ScaledImage from "../UI/ScaledImage"
import { Link } from "gatsby"
import LiftingIcon from "../Icons/LiftingIcon"
import CapacityIcon from "../Icons/CapacityIcon"

const RelatedProducts = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState(null)

  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser &&
          window?.location?.origin === "https://boeingmhc.digiteer.dev"
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/related-products/${slug}`
      )
      .then((res) => {
        setProducts(res.data)
      })
      .finally(() => setLoading(false))
  }, [slug])

  if (loading) {
    return null
  }

  return (
    products.length > 0 && (
      <Element pt={10}>
        <Heading color="text" mb={4} fs="h5">
          Related Products
        </Heading>
        <Element mt={6}>
          <Swiper slidesPerView={4} spaceBetween={16}>
            {products.map((p) => (
              <SwiperSlide key={p.id}>
                <ThumbLink href={`/products/${p.slug}`}>
                  <Thumb>
                    <ScaledImage scaleWidth={1} scaleHeight={1}>
                      <img src={p.thumbnail} alt={p.name} />
                    </ScaledImage>
                    <ThumbOverlay>
                      <Element width="100%">
                        {p.max_height && (
                          <Element mb={2}>
                            <LiftingIcon />
                            <Element as="span" ml={4}>
                              {p.max_height}
                            </Element>
                          </Element>
                        )}
                        {p.max_lift && (
                          <Element>
                            <CapacityIcon />
                            <Element as="span" ml={4}>
                              {p.max_lift}
                            </Element>
                          </Element>
                        )}
                      </Element>
                    </ThumbOverlay>
                  </Thumb>
                  <Element mt={4} textAlign="center" fontWeight="600">
                    {p.code}
                  </Element>
                </ThumbLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </Element>
      </Element>
    )
  )
}

const Thumb = styled.div`
  position: relative;
`

const ThumbOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-weight: bold;
  background: linear-gradient(
    0deg,
    rgba(84, 85, 181, 0.6),
    rgba(84, 85, 181, 0.6)
  );
  opacity: 0;
  transition: var(--transition);
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;

  svg {
    font-size: 24px;
  }
`

const ThumbLink = styled.a`
  position: relative;
  display: block;
  color: var(--text);

  &:hover {
    color: var(--brand);

    ${ThumbOverlay} {
      opacity: 1;
    }
  }
`

export default RelatedProducts
