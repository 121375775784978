import React from "react"
import Icon from "../UI/Icon"

const CapacityIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.3612 29.8517C39.3216 29.6123 39.2351 29.3831 39.1067 29.1772C38.9783 28.9713 38.8105 28.7928 38.613 28.6518C38.4154 28.5109 38.192 28.4103 37.9555 28.3559C37.7191 28.3015 37.4742 28.2943 37.2349 28.3347L19.5665 31.2794C20.0295 32.0288 20.3098 32.9021 20.3098 33.8464C20.3098 34.216 20.259 34.5735 20.1839 34.9187L37.8442 31.974C38.3267 31.8929 38.7575 31.6237 39.0419 31.2255C39.3263 30.8273 39.4411 30.3325 39.3612 29.8497V29.8517ZM15.385 28.9236C15.7668 28.9236 16.1323 28.9764 16.4877 29.0577L12.2859 2.78455C12.2232 2.3956 12.0379 2.03684 11.757 1.76064C11.476 1.48445 11.1141 1.30526 10.7242 1.24923L2.10727 0.0185362C1.86725 -0.0157342 1.62282 -0.00239284 1.38795 0.0577993C1.15308 0.117991 0.93237 0.223855 0.738413 0.369346C0.3467 0.663178 0.0877556 1.10058 0.0185435 1.58534C-0.0506685 2.07009 0.0755214 2.56248 0.369354 2.9542C0.663186 3.34591 1.10059 3.60485 1.58535 3.67407L8.85376 4.71183L12.8444 29.6507C13.6081 29.179 14.4873 28.9274 15.385 28.9236ZM38.033 23.6292L35.594 8.54608C35.4878 7.9026 35.1313 7.32727 34.6025 6.94565C34.0736 6.56403 33.4152 6.40711 32.7711 6.50913L17.6859 8.95022C17.0423 9.05645 16.4668 9.4128 16.0849 9.94159C15.7029 10.4704 15.5455 11.1287 15.647 11.7731L17.887 25.6032C19.6538 26.1393 21.177 27.2319 22.2635 28.6738L35.9961 26.4521C36.6401 26.3469 37.2161 25.9907 37.5979 25.4616C37.9797 24.9324 38.1362 24.2735 38.033 23.6292Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3856 36.3099C15.0621 36.3099 14.7417 36.2462 14.4429 36.1224C14.144 35.9986 13.8724 35.8172 13.6437 35.5884C13.4149 35.3597 13.2335 35.0881 13.1097 34.7892C12.9859 34.4904 12.9221 34.17 12.9221 33.8465C12.9221 33.523 12.9859 33.2027 13.1097 32.9038C13.2335 32.6049 13.4149 32.3334 13.6437 32.1046C13.8724 31.8759 14.144 31.6944 14.4429 31.5706C14.7417 31.4468 15.0621 31.3831 15.3856 31.3831C16.0264 31.4015 16.6348 31.669 17.0816 32.1288C17.5283 32.5886 17.7783 33.2044 17.7783 33.8455C17.7783 34.4866 17.5283 35.1024 17.0816 35.5622C16.6348 36.022 16.0264 36.2895 15.3856 36.3079V36.3099ZM15.3856 27.693C13.7536 27.693 12.1884 28.3414 11.0344 29.4954C9.8804 30.6494 9.23208 32.2145 9.23208 33.8465C9.23208 35.4785 9.8804 37.0437 11.0344 38.1977C12.1884 39.3517 13.7536 40 15.3856 40C17.0176 40 18.5827 39.3517 19.7367 38.1977C20.8907 37.0437 21.539 35.4785 21.539 33.8465C21.539 32.2145 20.8907 30.6494 19.7367 29.4954C18.5827 28.3414 17.0176 27.693 15.3856 27.693Z"
      />
    </Icon>
  )
}

export default CapacityIcon
