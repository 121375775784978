import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Site from "../../components/Layout/Site"
import { Col, Container, Row } from "../../components/UI/Grid"
import { Heading, Paragraph } from "../../components/UI/Typography"
import { Section } from "../../components/UI/Section"
import HeavyLifting from "../../components/Shared/HeavyLifting"
import { Nav, Tab } from "react-bootstrap"
import Element from "../../components/UI/Element"
import CapacityIcon from "../../components/Icons/CapacityIcon"
import DownloadIcon from "../../components/Icons/DownloadIcon"
import LiftingIcon from "../../components/Icons/LiftingIcon"
import styled from "styled-components"
import theme from "../../utils/theme"
import { Swiper, SwiperSlide } from "swiper/react"
import { Thumbs } from "swiper"
import ScaledImage from "../../components/UI/ScaledImage"
import Button from "../../components/UI/Button"
import RelatedProducts from "../../components/Products/RelatedProducts"

const Product = ({ location, data }) => {
  const [processing, setProcessing] = useState(true)
  const [product, setProduct] = useState(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser &&
          window?.location?.origin === "https://boeingmhc.digiteer.dev"
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/${location.pathname}`
      )
      .then((res) => {
        if (res.data) {
          setProduct(res.data)
        } else {
          navigate("/404")
        }
      })
      .finally(() => setProcessing(false))
  }, [location.pathname])

  if (processing) {
    return null
  }

  return (
    <Site>
      <Section>
        <Container>
          <Row textAlign="center" justifyContent="center" mb={10}>
            <Col col={{ lg: 6 }}>
              <Element color="var(--brand)">{product.code}</Element>
              <Heading fs="h2">{product.name}</Heading>
              <Paragraph mt={6}>{product.description}</Paragraph>
            </Col>
          </Row>

          {product.photos.length > 1 ? (
            <>
              <Row justifyContent="center" mb={8}>
                <Col col={{ lg: 5 }}>
                  <Swiper modules={[Thumbs]} thumbs={{ swiper: thumbsSwiper }}>
                    {product?.photos?.map((photo, idx) => (
                      <SwiperSlide key={idx}>
                        <ScaledImage scaleWidth={1} scaleHeight={1}>
                          <img src={photo} alt={product.name}></img>
                        </ScaledImage>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>
              </Row>
              <Row justifyContent="center" mb={8}>
                <Col col={{ lg: 7 }}>
                  <Swiper
                    modules={[Thumbs]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    slidesPerView={5}
                    spaceBetween={36}
                  >
                    {product?.photos?.map((photo, idx) => (
                      <SwiperSlide key={idx}>
                        <ScaledImage scaleWidth={1} scaleHeight={1}>
                          <img src={photo} alt={product.name}></img>
                        </ScaledImage>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>
              </Row>
            </>
          ) : (
            <Row justifyContent="center" mb={8}>
              <Col col={{ lg: 5 }}>
                <ScaledImage scaleWidth={1} scaleHeight={1}>
                  <img src={product.thumbnail} alt={product.name}></img>
                </ScaledImage>
              </Col>
            </Row>
          )}
          <Row textAlign="center" justifyContent="center" mb={10}>
            <Col col={{ lg: 8 }}>
              <Element bg="#EEEEF8" color="var(--brand)" p={4} mb={6}>
                <Row justifyContent="space-around" textAlign="center">
                  <Col col={{ lg: 6 }}>
                    <Row justifyContent="center" alignItems="center">
                      <Col col="auto">
                        <Element fs="h1" lineHeight={1}>
                          <CapacityIcon />
                        </Element>
                      </Col>
                      <Col col="auto">MAX LOADING CAPACITY</Col>
                    </Row>
                    <Heading fs="h4">{product.max_lift}</Heading>
                  </Col>
                  <Col col={{ lg: 6 }}>
                    <Row justifyContent="center" alignItems="center">
                      <Col col="auto">
                        <Element fs="h1" lineHeight={1}>
                          <LiftingIcon />
                        </Element>
                      </Col>
                      <Col col="auto">MAX LIFTING HEIGHT</Col>
                    </Row>
                    <Heading fs="h4">{product.max_height}</Heading>
                  </Col>
                </Row>
              </Element>
            </Col>
          </Row>

          <Row>
            <Col col={{ lg: 12 }}>
              <TabStyle>
                <Tab.Container defaultActiveKey="first">
                  <Row>
                    <Col col={{ lg: 6 }}>
                      <Nav as={Row} mb={6} justifyContent="space-between">
                        <Nav.Item as={Col} col={{ _: "auto" }}>
                          <Nav.Link eventKey="first">Product Overview</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as={Col} col={{ _: "auto" }}>
                          <Nav.Link eventKey="second">Features</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as={Col} col={{ _: "auto" }}>
                          <Nav.Link eventKey="third">Model Overview</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.product_overview,
                            }}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.features,
                            }}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.model_overview,
                            }}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </TabStyle>
            </Col>
          </Row>

          <Row>
            {product.brochure_file_url && (
              <Col col="auto" mt={6}>
                <Button
                  size="lg"
                  variant="brand"
                  as="a"
                  href={product.brochure_file_url}
                  download={product.brochure_file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Element as="span" mr={8}>
                    Download Brochure
                  </Element>
                  <DownloadIcon />
                </Button>
              </Col>
            )}
            {product.tech_specs_file_url && (
              <Col col="auto" mt={6}>
                <Button
                  size="lg"
                  variant="brand-outline"
                  as="a"
                  href={product.tech_specs_file_url}
                  download={product.tech_specs_file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Element as="span" mr={8}>
                    Download Technical Specifications
                  </Element>
                  <DownloadIcon />
                </Button>
              </Col>
            )}
          </Row>

          <RelatedProducts slug={product.slug} />
        </Container>
      </Section>
      <HeavyLifting />
    </Site>
  )
}

const TabStyle = styled.div`
  .nav-link {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 4px;
    border-bottom: 3px solid transparent;
    color: #9e9e9e;

    &.active {
      color: ${theme.colors.text};
      border-bottom-color: var(--brand);
    }
  }
`

export default Product
