import React from "react"
import Icon from "../UI/Icon"

const DownloadIcon = () => {
  return (
    <Icon viewBox="0 0 24 24">
      <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" />
    </Icon>
  )
}

export default DownloadIcon
